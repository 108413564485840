<template>
  <a-layout class="app-layout">
    <app-header :nav-type="navType"></app-header>
    <div class="app-layout_bg app-layout_bg-top" v-if="bgColor === 'bg_blue'"></div>
    <div class="app-layout_bg app-layout_bg-bottom" v-else></div>
    <a-layout-content class="app-layout_content">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component"  v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <component :is="Component"  v-if="!$route.meta.keepAlive"/>
      </router-view> 
    </a-layout-content>
  </a-layout>
</template>

<script>
import AppHeader from './AppHeader';
import { onMounted, reactive, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    AppHeader
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      navType: 0,
      bgColor: ''
    });

    // 设置导航栏类型
    const setNavType = () => {
      window.scrollTo(0, 0);
      let prePath = route.path.split('?')[0]?.split('/')[1];
      if (['', 'in-mail', 'live-broadcast-watch', 'video-playback-watch'].includes(prePath)) {
        state.bgColor = 'bg_blue';
        state.navType = 1;
        return;
      }
      state.bgColor = '';
      state.navType = 0;
    };

    // 滚动
    const handleScroll = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let prePath = route.path.split('?')[0]?.split('/')[1];
      if (['', 'in-mail', 'live-broadcast-watch', 'video-playback-watch'].includes(prePath)) {
        // [
        //   'personal-center',
        //   'live-broadcast',
        //   'video-playback',
        //   'inspection',
        //   'video-playback-watch',
        //   'live-broadcast-watch'
        // ]
        state.bgColor = 'bg_blue';
        if (scrollTop < 10) state.navType = 1;
        else state.navType = 0;
      }
    };

    watch(
      () => route.path,
      () => {
        setNavType();
      }
    );

    onMounted(() => {
      setNavType();
      window.addEventListener('scroll', handleScroll, true);
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="less" scoped>
@bg: '~@imgs/layout/bg.png';
@bg_bottom: '~@imgs/layout/bg_bottom.png';

.app-layout {
  position: relative;
  background: #f6f6f6;
  // height: 100%;
  min-height: 960px;
  overflow: auto;

  &_bg {
    position: absolute;
    width: 100%;

    &-top {
      height: 750px;
      left: 0;
      top: 0;
      background: url('@{bg}') no-repeat center / 100% 100%;
    }

    &-bottom {
      height: unset;
      left: 0;
      bottom: 0;
      background: url('@{bg_bottom}') no-repeat center / 100% 100%;
    }
  }

  &_content {
    margin: 72px auto 0;
    width: 1440px;
    z-index: 1;
  }
}
</style>
